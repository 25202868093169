import { useEffect, useState } from "react";
import { cuteNumber, setCookie, getCookie } from '../functions/Functions'
import { useMyContext } from "../context/GlobalContext";

function BlockchainInfo() {
    const cookieMassaPriceName = 'coingecko';
    const cookieMassaInfoName = 'massaInfo';
    const [massaInfo, setMassaInfo] = useState<any>(undefined);
    const {massaPrice} = useMyContext();

    const getMassaInfo = async () => {
        console.log("Getting blockchain informations..");
        try {
          const massainfo = await fetch("https://explorer-api.massa.net/info");
          if (!massainfo.ok) {
            throw new Error('Error Massa Info');
          }
          let json = await massainfo.json();
          json = {"total_supply": json["total_supply"], "circulating_supply": json["circulating_supply"]};
          const jsonToString = JSON.stringify(json);
          setMassaInfo(json);
          setCookie(cookieMassaInfoName, jsonToString);
        } catch {
          setMassaInfo(undefined);
        }
    }

    useEffect(() => {
      if(getCookie(cookieMassaInfoName) === 0) {
        getMassaInfo();
      }
      else {
        setMassaInfo(JSON.parse(getCookie(cookieMassaInfoName)));
      };
    }, []);

    return (
    <div className="mt-3 mb-10">
        <div className="text-2xl">Blockchain informations</div>
        <div className="hidden md:flex rounded py-3 bg-blue-100">
          <div className="mx-auto uppercase text-center">
            <div className="text-blue-400">Massa Price</div>
            <div className="font-bold">{massaPrice ? "$ "+cuteNumber(massaPrice, 8) : "-"}</div>
          </div>
          <div className="mx-auto uppercase text-center">
            <div className="text-blue-400">Market Cap</div>
            <div className="font-bold">{massaPrice && massaInfo ? "$ "+cuteNumber(massaPrice*massaInfo["circulating_supply"], 0) : "-"}</div>
          </div>
          <div className="mx-auto uppercase text-center">
            <div className="text-blue-400">FDV</div>
            <div className="font-bold">{massaPrice && massaInfo ? "$ "+cuteNumber(massaPrice*massaInfo["total_supply"], 0) : "-"}</div>
          </div>
          <div className="mx-auto uppercase text-center">
            <div className="text-blue-400">Circulating Supply{massaInfo && <span className="text-sm ms-1">({cuteNumber(massaInfo["circulating_supply"]*100/massaInfo["total_supply"], 0)}%)</span>}</div>
            <div className="font-bold">{massaInfo ? cuteNumber(massaInfo["circulating_supply"], 0)+" MAS" : "-"}</div>
          </div>
          <div className="mx-auto uppercase text-center">
            <div className="text-blue-400">Total Supply</div>
            <div className="font-bold">{massaInfo ? cuteNumber(massaInfo["total_supply"], 0)+" MAS" : "-"}</div>
          </div>
        </div>
        <div className="flex md:hidden rounded py-3 bg-blue-50">
          <div className="mx-auto w-1/2 uppercase text-center">
            <div className="text-blue-400">Massa Price</div>
            <div className="font-bold">{massaPrice ? "$ "+cuteNumber(massaPrice, 8) : "-"}</div>
          </div>
          <div className="mx-auto w-1/2 uppercase text-center">
            <div className="text-blue-400">Market Cap</div>
            <div className="font-bold">{massaPrice && massaInfo ? "$ "+cuteNumber(massaPrice*massaInfo["circulating_supply"], 0) : "-"}</div>
          </div>
        </div>
        <div className="flex md:hidden rounded py-3 bg-blue-50">
          <div className="mx-auto w-1/2 uppercase text-center">
            <div className="text-blue-400">Circulating Supply{massaInfo && <span className="text-sm ms-1">({cuteNumber(massaInfo["circulating_supply"]*100/massaInfo["total_supply"], 0)}%)</span>}</div>
            <div className="font-bold">{massaInfo ? cuteNumber(massaInfo["circulating_supply"], 0)+" MAS" : "-"}</div>
          </div>
          <div className="mx-auto w-1/2 uppercase text-center">
            <div className="text-blue-400">FDV</div>
            <div className="font-bold">{massaPrice && massaInfo ? "$ "+cuteNumber(massaPrice*massaInfo["total_supply"], 0) : "-"}</div>
          </div>
        </div>
    </div>
    );
}
        
export default BlockchainInfo;