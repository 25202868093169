import React, { useEffect, useState } from "react";
import { OperationData } from "../interfaces/Interfaces"
import OperationList from "../modules/OperationList";
import { useParams } from "react-router-dom";
import SearchBar from "../modules/SearchBar";

const TokenExplorerToken: React.FC = () => {
  const { token, page } = useParams();
  const [transferts, setTransferts] = useState<OperationData[]>([]);
  const [nbPages, setnbPages] = useState<number>();
  const [error, setError] = useState<string | null>(null);
  
  const fetchTransferts = async () => {
    try {
      let selectedPage = 0;
      if(page) {
        selectedPage = parseInt(page)-1;
        if(selectedPage < 0) selectedPage = 0;
      }

      const responseTransferts = await fetch('https://api.massa.ga/token/' + token + '/' + selectedPage);
      if (!responseTransferts.ok) {
        throw new Error('Error retrieving data..');
      }
      const resultTransferts: OperationData[] = await responseTransferts.json();
      setTransferts(resultTransferts);
      
      if(resultTransferts.length) setError(null);
      else setError('Invalid token address or no operation..');
    } catch (error) {
      setError('Error retrieving data..');
      setTransferts([]);
    }
  };

  const fetchNbPages = async () => {
    try {
      const responseNbPages = await fetch('https://api.massa.ga/token_count/' + token);
      if (!responseNbPages.ok) {
        throw new Error('Error retrieving data..');
      }
      const resulNbPages = await responseNbPages.json();
      setnbPages(resulNbPages.NB_PAGES);
      setError(null);
    } catch (error) {
      setError('Error retrieving data..');
      setnbPages(0);
    }
  };

  useEffect(() => {
    fetchTransferts();
    fetchNbPages();

    const interval = setInterval(() => {
      fetchTransferts();
      fetchNbPages();
    }, 5000);

    return () => clearInterval(interval);
  }, [token, page]);

  return (
  <div>
    <SearchBar />
    <div className="mt-3 mb-5">
      <div className="text-xl mb-5 truncate"><kbd>{transferts[0] ? transferts[0]["NAME"] + " (" + transferts[0]["SYMBOL"] + ")" : token}</kbd></div>
      <OperationList transferts={transferts} nbPages={(nbPages?nbPages:0)} error={error}/>
    </div>
  </div>
  )
};

export default TokenExplorerToken;
