import { ClientFactory, DefaultProviderUrls, MAINNET_CHAIN_ID, BUILDNET_CHAIN_ID, Args } from '@massalabs/massa-web3';
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import * as wasmparser from 'wasmparser';
import * as wasmdis from 'wasmparser/dist/cjs/WasmDis';

interface Props {
    mainnet: boolean;
}

function AnalyzerScan(props: Props) {
    const { mainnet } = props;
    const { scaddress } = useParams();
    const [ web3Client, setWeb3Client ] = useState<any | undefined>(undefined);
    const [ error, setError ] = useState<string | undefined>(undefined);
    const [ infos, setInfos ] = useState<string | undefined>(undefined);
    const [ wasm, setWasm ] = useState<Uint8Array | undefined>(undefined);
    const [ wat, setWat ] = useState<string | undefined>(undefined);
    const [ importedABIs, setImportedABIs ] = useState<string[] | undefined>(undefined);
    const [ exportedFunctions, setExportedFunctions ] = useState<string[] | undefined>(undefined);
    const [ exportedGlobals, setExportedGlobals ] = useState<string[] | undefined>(undefined);
    const [ exportedMemories, setExportedMemories ] = useState<string[] | undefined>(undefined);
    const [ sourceMapName, setSourceMapName ] = useState<string | undefined>(undefined);
    const [ constants, setConstants ] = useState<string[] | undefined>(undefined);
    const [ declaredTexts, setDeclaredTexts ] = useState<string[] | undefined>(undefined);
    const [ declaredLibs, setDeclaredLibs ] = useState<string[] | undefined>(undefined);

    const initialiseWeb3Client = async () => {
        setInfos("Initialize web3client..");

        if(mainnet) {
            setInfos("MainNet selected");
            setWeb3Client(await ClientFactory.createDefaultClient(DefaultProviderUrls.MAINNET, MAINNET_CHAIN_ID));
        }
        else {
            setInfos("BuildNet selected");
            setWeb3Client(await ClientFactory.createDefaultClient(DefaultProviderUrls.BUILDNET, BUILDNET_CHAIN_ID));
        }
    }

    const scanSC = async () => {
        setError(undefined);
        setWasm(undefined);
        setWat(undefined);
        setImportedABIs(undefined);
        setExportedFunctions(undefined);
        setExportedGlobals(undefined);
        setExportedMemories(undefined);
        setSourceMapName(undefined);
        setConstants(undefined);
        setDeclaredTexts(undefined);
        setDeclaredLibs(undefined);

        if(web3Client === undefined) return;

        if(scaddress) {
            setInfos("Getting bytecode of SC..");
            try {
                const readOnlyResult = await web3Client.smartContracts().readSmartContract({
                    targetAddress: mainnet ? "AS1ujc1giaHffG7rmA9K677KGNjmz7bxr5ynfn67KzJ7fyc3tBRh"
                                            :"AS123T6tfdY5mUkUomGXdTdrVcPP5p3WJVRJECCVPFTFcpUDyqaJj",
                    targetFunction: 'getWasm',
                    parameter: new Args().addString(scaddress.trim()),
                });

                if (readOnlyResult.returnValue.length === 0) {
                    setError('Empty bytecode');
                }

                setWasm(readOnlyResult.returnValue);
                setInfos("Bytecode of SC: OK");
            } catch(e) {
                const error = e as Error;
                setError(error.message);
                setInfos("Bytecode of SC: ERROR");
            }
        }
    }

    const wasmToWat = () => {
        if(!wasm) return;

        setInfos("Converting WASM to WAT..");
        const parser = new wasmparser.BinaryReader();
        parser.setData(wasm.buffer, 0, wasm.length);
        const namesReader = new wasmdis.NameSectionReader();
        namesReader.read(parser);

        parser.setData(wasm.buffer, 0, wasm.length);
        const dis = new wasmdis.WasmDisassembler();
        if (namesReader.hasValidNames()) {
            dis.nameResolver = namesReader.getNameResolver();
        }

        setWat(dis.disassemble(parser));
        setInfos("Convertion WASM to WAT: OK");
    }

    const fImportedABIs = () => {
        if(!wat) return;
        const regex = /assembly_script_(\w+)/gi;

        const matches: RegExpExecArray[] = [];
        let match: RegExpExecArray | null;

        while ((match = regex.exec(wat)) !== null) {
            matches.push(match);
        }

        setImportedABIs(matches.map((match) => match[1]));
        setInfos("Imported ABIs: OK");
    }

    const fExportedFunctions = () => {
        if(!wat) return;

        const regex = /\(export "(\w+)" \(func/gi;

        const matches: RegExpExecArray[] = [];
        let match: RegExpExecArray | null;

        while ((match = regex.exec(wat)) !== null) {
            matches.push(match);
        }

        setExportedFunctions(matches.map((match) => match[1]));
        setInfos("Exported Functions: OK");
    }

    const fExportedGlobals = () => {
        if(!wat) return;

        const regex = /\(export "(\w+)" \(global/gi;

        const matches: RegExpExecArray[] = [];
        let match: RegExpExecArray | null;

        while ((match = regex.exec(wat)) !== null) {
            matches.push(match);
        }

        setExportedGlobals(matches.map((match) => match[1]));
        setInfos("Exported Globals: OK");
    }

    const fExportedMemories = () => {
        if(!wat) return;

        const regex = /\(export "(\w+)" \(memory/gi;

        const matches: RegExpExecArray[] = [];
        let match: RegExpExecArray | null;

        while ((match = regex.exec(wat)) !== null) {
            matches.push(match);
        }

        setExportedMemories(matches.map((match) => match[1]));
        setInfos("Exported Memories: OK");
    }

    const fSourceMapName = () => {
        if(!wasm) return;

        const wasmString = Buffer.from(wasm).toString('utf-8');
        const regex = /sourceMappingURL.*\.\/(.+)\.wasm\.map/gi;
  
        let match: RegExpExecArray | null;
        while ((match = regex.exec(wasmString)) !== null) {
            setSourceMapName(match[1]);
            setInfos("Source Map Name: OK");
        }
    }

    const fData = () => {
        if(!wat) return [];

        setConstants(wat
          .split('\n')
          .filter((l) => l.includes('i32.const') && l.includes('data'))
          .map((l) => {
            const lineSlit = l.split('"');
            if (lineSlit.length < 2) return '';
            const encodedString = lineSlit[1];
            const lineSlit2 = encodedString.split('\\');
            if (lineSlit2.length < 2) return '';
            const encodedString2 = lineSlit2.join('\\');
            const text = encodedString2.split('\\00').join('');
            return text.replace(/\\/g, '').slice(3);
          })
          .filter((l) => l.length > 2));

        setInfos("Constants: OK");
    }

    const fDeclaredTexts = () => {
        if(!constants) return;

        setDeclaredTexts(constants.filter((c) => !c.includes('~lib')));
        setInfos("Declared Texts: OK");
    }

    const fDeclaredLibs = () => {
        if(!constants) return;

        setDeclaredLibs(constants
            .filter((c) => c.includes('~lib'))
            .map((c) => {
              const splitted = c.split('~lib');
              return splitted[splitted.length - 1].slice(1);
            }));
        setInfos("Declared Libs: OK");
    }

    useEffect(() => {
        initialiseWeb3Client();
    }, [scaddress, mainnet]);

    useEffect(() => {
        scanSC();
    }, [web3Client]);

    useEffect(() => {
        wasmToWat();
        fSourceMapName();
    }, [wasm]);

    useEffect(() => {
        fImportedABIs();
        fExportedFunctions();
        fExportedGlobals();
        fExportedMemories();
        fData();
    }, [wat]);

    useEffect(() => {
        fDeclaredTexts();
        fDeclaredLibs();
    }, [constants]);

    return (
    <div className="mt-3 mb-10">
        <div className="text-xl mb-5 truncate"><kbd>{scaddress}</kbd></div>
        {error ?
            <div className="my-2 bg-red-50 text-red-800 rounded p-2"><span className="font-bold">Error:</span> <kbd>{error}</kbd></div>
        :
            <div>
                <div className="bg-blue-50 rounded"><span className="m-2">WASM</span><textarea cols={30} rows={5} value={(wasm && wasm.toString())} defaultValue={"Loading.."} className="mb-3 block p-2.5 w-full text-sm rounded-lg text-gray-900 border border-blue-300 w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" /></div>
                <div className="bg-blue-50 rounded"><span className="m-2">WAT</span><textarea cols={30} rows={5} value={wat} defaultValue={"Loading.."} className="mb-3 block p-2.5 w-full text-sm rounded-lg text-gray-900 border border-blue-300 w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" /></div>
                <div className="bg-blue-50 rounded"><span className="m-2">Imported ABIs</span><textarea cols={30} rows={5} value={importedABIs && importedABIs.join('\n')} defaultValue={"Loading.."} className="mb-3 block p-2.5 w-full text-sm rounded-lg text-gray-900 border border-blue-300 w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" /></div>
                <div className="bg-blue-50 rounded"><span className="m-2">Exported Functions</span><textarea cols={30} rows={5} value={exportedFunctions && exportedFunctions.join('\n')} defaultValue={"Loading.."} className="mb-2 block p-2.5 w-full text-sm rounded-lg text-gray-900 border border-blue-300 w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" /></div>
                <div className="bg-blue-50 rounded"><span className="m-2">Exported Globals</span><textarea cols={30} rows={5} value={exportedGlobals && exportedGlobals.join('\n')} defaultValue={"Loading.."} className="mb-3 block p-2.5 w-full text-sm rounded-lg text-gray-900 border border-blue-300 w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" /></div>
                <div className="bg-blue-50 rounded"><span className="m-2">Exported Memories</span><textarea cols={30} rows={5} value={exportedMemories && exportedMemories.join('\n')} defaultValue={"Loading.."} className="mb-3 block p-2.5 w-full text-sm rounded-lg text-gray-900 border border-blue-300 w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" /></div>
                <div className="bg-blue-50 rounded"><span className="m-2">Declared Texts</span><textarea cols={30} rows={5} value={declaredTexts && declaredTexts.join('\n')} defaultValue={"Loading.."} className="mb-3 block p-2.5 w-full text-sm rounded-lg text-gray-900 border border-blue-300 w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" /></div>
                <div className="bg-blue-50 rounded"><span className="m-2">Declared Libs</span><textarea cols={30} rows={5} value={declaredLibs && declaredLibs.join('\n')} defaultValue={"Loading.."} className="block p-2.5 w-full text-sm rounded-lg text-gray-900 border border-blue-300 w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" /></div>
            </div>
        }
    </div>
    );
}
        
export default AnalyzerScan;