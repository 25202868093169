import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CHAIN_ID, ClientFactory, DefaultProviderUrls, bytesToStr, Client, byteToU8, byteToBool, byteToU16, bytesToU32, bytesToU64, bytesToU128, bytesToU256, bytesToI16, bytesToI32, bytesToI64, bytesToI128, bytesToF32, bytesToF64 } from "@massalabs/massa-web3";
import DataStoredBar from "../modules/DataStoredBar";
import { copyToClipboard } from "../functions/Functions";

const DataStored: React.FC = () => {
  const { scaddress, network } = useParams();
  const [keys, setKeys] = useState<{} | null>();
  const [noKeys, setNoKeys] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [webClient, setwebClient] = useState<Client | null>(null);
  const [mainnet, setMainnet] = useState<boolean>();
  const [encodedValues, setEncodedValues] = useState<{ [key: string]: Uint8Array | null }>({});
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string | null }>({});

  const changeNetwork = (netw:boolean) => {
    if(mainnet !== undefined && mainnet !== netw)
    {
      const currentUrl = window.location.href.split("/")
      let newUrl;
      if(currentUrl.length < 5)
        newUrl = currentUrl.slice(0, 5).join("/") + "/AS..";
      else
        newUrl = currentUrl.slice(0, 5).join("/");
      newUrl = `${newUrl}/${netw?"mainnet":"buildnet"}`;
      window.history.pushState({}, '', newUrl);
    }
    setMainnet(netw);
  }

  const fetchKeys = async () => {
    try {
      setError(null);
      const providerUrl = mainnet ? DefaultProviderUrls.MAINNET : DefaultProviderUrls.BUILDNET;
      const chainId = mainnet ? CHAIN_ID.MainNet : CHAIN_ID.BuildNet;
      const web3Client = await ClientFactory.createDefaultClient(providerUrl, chainId, true);
      setwebClient(web3Client);
      if(!scaddress) {
        setError("No smart contract address..");
        return;
      }

      const address = await web3Client.publicApi().getAddresses([scaddress]);
      const keys = address[0].final_datastore_keys;

      let queryKeys = [];
      for (let i = 0; i < keys.length; i++) {
        queryKeys.push({
            address: scaddress,
            key: Uint8Array.from(keys[i]),
        });
      }

      if (keys.length) {
        setKeys(keys);
      }
      else {
        setKeys(null);
        setNoKeys(true);
      }
    } catch (error) {
      setKeys(null);
      const errorMessage = error instanceof Error ? error.message : String(error);
      setError(errorMessage);
    }
  };

  const fetchValue = async (key: Uint8Array) => {
    if(!webClient || !scaddress) return;

    const res = await webClient.publicApi().getDatastoreEntries([{
      address: scaddress,
      key: Uint8Array.from(key),
    }]);

    setEncodedValues((prevValues) => ({
      ...prevValues,
      [key.toString()]: res[0].final_value,
    }));
  }

  const select = (key: any, selected: string) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [key]: selected
    }));
  }

  const decodeValue = (value: Uint8Array, decodeTo: string | null) => {
    try {
      switch (decodeTo) {
        case "string":
          return bytesToStr(value);
        case "boolean":
          return byteToBool(value);
        case "u8":
        return byteToU8(value);
        case "u16":
        return byteToU16(value);
        case "i16":
        return bytesToI16(value);
        case "u32":
          return bytesToU32(value);
        case "i32":
          return bytesToI32(value);
        case "f32":
          return bytesToF32(value);
        case "u64":
          return bytesToU64(value);
        case "i64":
          return bytesToI64(value);
        case "f64":
          return bytesToF64(value);
        case "u128":
          return bytesToU128(value);
        case "i128":
          return bytesToI128(value);
        case "u256":
          return bytesToU256(value);
        default:
          if(value[0] >= 65 && value[0] <= 123)
            return bytesToStr(value);
          else
            return bytesToU64(value);
      }
    } catch {
      return value;
    }
  }

  useEffect(() => {
    setKeys(null);
    setNoKeys(false);
    
    if(scaddress && mainnet !== undefined) {
      fetchKeys();
    }
  }, [scaddress, mainnet]);

  useEffect(() => {
    if(!network || network === "mainnet")
      changeNetwork(true);
    else
      changeNetwork(false);
  }, []);

  return (
    <div className="mt-3">
      <div className="text-2xl truncate">Data Stored Reader <span className="text-sm"><button className={mainnet || mainnet === undefined?"font-bold underline text-blue-500":" text-blue-500 hover:underline"} onClick={() => changeNetwork(true)}>mainnet</button> | <button className={!mainnet && mainnet !== undefined?"font-bold underline text-blue-500":" text-blue-500 hover:underline"} onClick={() => changeNetwork(false)}>buildnet</button></span></div>
      <DataStoredBar mainnet={mainnet !== undefined ? mainnet : (!network || network === "mainnet"?true:false)} />
      {scaddress && scaddress.length > 5 ?
      <div>
        <div className="text-xl mb-5 mt-3 truncate"><kbd>{scaddress}</kbd> <span className="text-sm italic">({keys ? Object.values(keys).length : "0"} keys)</span></div>
        <div>
          {keys ?
            (
              <div>
                <div className="grid grid-cols-12 gap-4 rounded-md text-sm text-blue-950 bg-blue-100 text-center font-bold py-5 uppercase">
                  <div className="col-span-3">Key</div>
                  <div className="col-span-3">Value</div>
                  <div className="col-span-6">Decoded value</div>
                </div>
                <div className="block text-center border border-blue-100 rounded-md mt-1">
                  {Object.values(keys).map((key: any) => (
                    <div key={key} className="grid grid-cols-12 gap-4 py-2 rounded-md hover:bg-blue-50">
                      <div className="col-span-3 truncate">{bytesToStr(Uint8Array.from(key))}</div>
                      <div className="col-span-3 truncate">{encodedValues[key] ? "["+(encodedValues[key] as Uint8Array).join(',')+"]" : <button className="text-blue-500 hover:text-blue-600 hover:underline" onClick={() => fetchValue(key)}>fetch value</button>}</div>
                      <div className="col-span-6 truncate">{encodedValues[key] ?
                        <div>
                          <div>
                            <select defaultValue={
                                (encodedValues[key] as Uint8Array) && (encodedValues[key] as Uint8Array)[0] >= 65 && (encodedValues[key] as Uint8Array)[0] <= 123
                                ? "string"
                                : "u64"
                              } onChange={(e) => select(key, e.target.value)} className="bg-gray-50 border text-gray-900 border-blue-300 text-sm rounded-lg focus:ring-blue-300 focus:border-blue-300 block w-full p-2.5">
                              <option value="boolean">Boolean</option>
                              <option value="string">String</option>
                              <option value="u8">U8</option>
                              <option value="u16">U16</option>
                              <option value="i16">I16</option>
                              <option value="u32">U32</option>
                              <option value="i32">I32</option>
                              <option value="f32">F32</option>
                              <option value="u64">U64</option>
                              <option value="i64">I64</option>
                              <option value="f64">F64</option>
                              <option value="u128">U128</option>
                              <option value="i128">I128</option>
                              <option value="u256">U256</option>
                            </select>
                          </div>
                          <div className="truncate">
                          {encodedValues[key] ? (
                            selectedValues[key] ? 
                              decodeValue(encodedValues[key] as Uint8Array, selectedValues[key]).toString()
                            :
                              decodeValue(encodedValues[key] as Uint8Array, "default").toString()
                          ) : (
                            "-"
                          )}
                          </div>
                        </div>
                      : "-"}</div>
                    </div>
                  ))}
                </div>
              </div>
            )
          :
            (error ?
              <div className="font-bold text-red-500">Error: {error}</div>
            :
              (noKeys ?
                <div className="font-bold text-blue-500">No keys!</div>
              :
                <div className="text-blue-500">Loading..</div>
              )
            )
          }
        </div>
      </div>
      :
      <div>
        <div className="text-xl truncate">Example</div>
        <div className="text-sm truncate mt-2">
          <div>Smart contract</div>
          <span className="font-bold size-20" onClick={() => copyToClipboard("AS12qzyNBDnwqq2vYwvUMHzrtMkVp6nQGJJ3TETVKF5HCd4yymzJP")}>AS12qzyNBDnwqq2vYwvUMHzrtMkVp6nQGJJ3TETVKF5HCd4yymzJP</span>
        </div>
      </div>
    }
    </div>
  );
};

export default DataStored;
