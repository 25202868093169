import React from "react";
import BlockchainInfo from "../modules/BlockchainInfo";

const Home: React.FC = () => {
  return (
    <div>
      <BlockchainInfo />
      <div className="text-4xl mt-10">Welcome to <span className="font-bold">massa.ga</span> !</div>
    </div>
  );
};

export default Home;
