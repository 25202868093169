import React, { useState } from "react";
import { copyToClipboard } from '../functions/Functions'

const Donate: React.FC = () => {
  const [copied, setCopied] = useState<boolean>(false);

  async function copy() {
    if(await copyToClipboard("AU1L3YbT7SBwxdVwzacoonEgou5oXi5mNfXMaXqYhoL69GVrDUrE"))
    {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }

  return (
    <div className="mt-3 mb-5">
      <div className="text-2xl truncate">Donate to LaPerle</div>
      <div className="truncate"><kbd onClick={copy} className="bg-blue-50 p-2 rounded">AU1L3YbT7SBwxdVwzacoonEgou5oXi5mNfXMaXqYhoL69GVrDUrE</kbd></div>
      <div>{copied && <span className="ms-2 text-sm italic">copied to clipboard</span>}</div>
    </div>
  );
};

export default Donate;
