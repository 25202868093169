import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MyContextProvider } from "./components/context/GlobalContext"
import NotFound from "./components/pages/NotFound";
import Header from "./components/pages/Header";
import Home from "./components/pages/Home";
import TokenExplorer from './components/pages/TokenExplorer';
import TokenExplorerAddress from './components/pages/TokenExplorerAddress';
import TokenExplorerOperation from './components/pages/TokenExplorerOperation';
import TokenExplorerToken from './components/pages/TokenExplorerToken';
import TokenExplorerBlock from './components/pages/TokenExplorerBlock';
import Footer from './components/pages/Footer';
import Donate from './components/pages/Donate';
import EventsViewer from './components/pages/EventsViewer';
import Portfolio from './components/pages/Portfolio';
import SCAnalyzer from './components/pages/SCAnalyzer';
import DataStored from './components/pages/DataStored';
import SCReader from './components/pages/SCReader';

const App: React.FC = () =>  {
  return (
      <Router>
        <MyContextProvider>
          <Header />
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 py-3 mt-4 text-blue-950 pt-20">
            <Routes>
              <Route path="/" element={<Home />} />

              <Route path="/token-explorer" element={<TokenExplorer />} />
              <Route path="/token-explorer/block/:block" element={<TokenExplorerBlock />} />
              <Route path="/token-explorer/block/:block/:page" element={<TokenExplorerBlock />} />
              <Route path="/token-explorer/operation/:operation" element={<TokenExplorerOperation />} />
              <Route path="/token-explorer/address/:address" element={<TokenExplorerAddress />} />
              <Route path="/token-explorer/address/:address/:page" element={<TokenExplorerAddress />} />
              <Route path="/token-explorer/token/:token" element={<TokenExplorerToken />} />
              <Route path="/token-explorer/token/:token/:page" element={<TokenExplorerToken />} />

              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/portfolio/:address" element={<Portfolio />} />

              <Route path="/events-viewer" element={<EventsViewer />} />
              <Route path="/events-viewer/smart-contract/:smartcontract" element={<EventsViewer />} />
              <Route path="/events-viewer/smart-contract/:smartcontract/:network" element={<EventsViewer />} />
              <Route path="/events-viewer/operation/:operation" element={<EventsViewer />} />
              <Route path="/events-viewer/operation/:operation/:network" element={<EventsViewer />} />
              <Route path="/events-viewer/address/:address" element={<EventsViewer />} />
              <Route path="/events-viewer/address/:address/:network" element={<EventsViewer />} />

              <Route path="/sc-analyzer" element={<SCAnalyzer />} />
              <Route path="/sc-analyzer/:scaddress" element={<SCAnalyzer />} />
              <Route path="/sc-analyzer/:scaddress/:network" element={<SCAnalyzer />} />

              <Route path="/sc-reader" element={<SCReader />} />
              <Route path="/sc-reader/:network" element={<SCReader />} />

              <Route path="/data-stored-reader" element={<DataStored />} />
              <Route path="/data-stored-reader/:scaddress" element={<DataStored />} />
              <Route path="/data-stored-reader/:scaddress/:network" element={<DataStored />} />

              <Route path="/donate" element={<Donate />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </MyContextProvider>
      </Router>
  );
}

export default App;
