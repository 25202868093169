import React, { useEffect, useState } from "react";
import EventsBar from "../modules/EventsBar";
import { copyToClipboard, formatTimeAgo } from '../functions/Functions'
import { useParams } from "react-router-dom";
import { CHAIN_ID, ClientFactory, DefaultProviderUrls } from "@massalabs/massa-web3";

const EventsViewer: React.FC = () => {
  const { smartcontract, operation, address, network } = useParams();
  const [events, setEvents] = useState<{} | null>();
  const [noEvents, setNoEvents] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [mainnet, setMainnet] = useState<boolean>();

  const changeNetwork = (netw:boolean) => {
    if(mainnet !== undefined && mainnet !== netw)
    {
      const currentUrl = window.location.href.split("/");
      let newUrl;
      if(currentUrl.length < 6)
        newUrl = currentUrl.slice(0, 6).join("/") + "/smart-contract/AS..";
      else
        newUrl = currentUrl.slice(0, 6).join("/");
      newUrl = `${newUrl}/${netw?"mainnet":"buildnet"}`;
      window.history.pushState({}, '', newUrl);
    }
    setMainnet(netw);
  }

  const fetchEvents = async () => {
    try {
      setError(null);
      const providerUrl = mainnet ? DefaultProviderUrls.MAINNET : DefaultProviderUrls.BUILDNET;
      const chainId = mainnet ? CHAIN_ID.MainNet : CHAIN_ID.BuildNet;
      const web3Client = await ClientFactory.createDefaultClient(providerUrl, chainId, true);
      if(!operation && !smartcontract && !address) {
        setError("No operation id/smart contract address/wallet address..");
        return;
      }

      const event = await web3Client.smartContracts().getFilteredScOutputEvents({
        emitter_address: (smartcontract ? smartcontract : null),
        start: null,
        end: null,
        original_caller_address: (address ? address : null),
        original_operation_id: (operation ? operation : null),
        is_final: null,
      });
      
      if (event.length) {
        event.reverse();
        setEvents(event);
      }
      else {
        setEvents(null);
        setNoEvents(true);
      }
    } catch (error) {
      setEvents(null);
      const errorMessage = error instanceof Error ? error.message : String(error);
      setError(errorMessage);
    }
  };

  useEffect(() => {
    setEvents(null);
    setNoEvents(false);
    
    if((smartcontract || operation || address) && mainnet !== undefined) {
      fetchEvents();

      const interval = setInterval(() => {
        fetchEvents();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [smartcontract, operation, address, mainnet]);

  useEffect(() => {
    if(!network || network === "mainnet")
      changeNetwork(true);
    else
      changeNetwork(false);
  }, []);
  
  return (
  <div className="mt-3">
  <div className="text-2xl truncate">Events viewer <span className="text-sm"><button className={mainnet || mainnet === undefined?"font-bold underline text-blue-500":" text-blue-500 hover:underline"} onClick={() => changeNetwork(true)}>mainnet</button> | <button className={!mainnet && mainnet !== undefined?"font-bold underline text-blue-500":" text-blue-500 hover:underline"} onClick={() => changeNetwork(false)}>buildnet</button></span></div>
    <EventsBar mainnet={mainnet !== undefined ? mainnet : (!network || network === "mainnet"?true:false)} />
    {smartcontract || operation || address ?
      <div>
        <div className="text-xl mb-5 truncate"><kbd>{smartcontract || operation || address}</kbd></div>
        {events ?
          (Object.values(events).map((event: any) => (
            <div key={event["context"]["origin_operation_id"] + "_" + Math.random()} className={`${event["context"]["is_error"] ? "bg-red-50 border-0" : "bg-blue-50 border-0"} border rounded p-2 my-2`}>
              <div>Age <span className="font-bold">{formatTimeAgo(new Date("2024-01-15T11:00:00").getTime()+(((event["context"]["slot"]["period"]*16)+(event["context"]["slot"]["thread"]*0.5))*1000))}</span></div>
              <div className="truncate">Operation <span className="font-bold">{event["context"]["origin_operation_id"]}</span></div>
              <div className="truncate">Callee <span className="font-bold">{event["context"]["call_stack"][1]}</span></div>
              <div className="truncate">Caller <span className="font-bold">{event["context"]["call_stack"][0]}</span></div>
              <div className="mt-2">Event data
                <div className="px-2 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg break-all">{event["data"]}</div>
              </div>
            </div>
            ))
          )
          :
          (error ?
            <div className="font-bold text-red-500">Error: {error}</div>
          :
            (noEvents ?
              <div className="font-bold text-blue-500">No events!</div>
            :
              <div className="text-blue-500">Loading..</div>
            )
          )
        }
      </div>
    :
      <div>
        <div className="text-xl truncate">Examples</div>
        <div className="text-sm truncate mt-2">
          <div>Smart contract</div>
          <span className="font-bold size-20" onClick={() => copyToClipboard("AS12qzyNBDnwqq2vYwvUMHzrtMkVp6nQGJJ3TETVKF5HCd4yymzJP")}>AS12qzyNBDnwqq2vYwvUMHzrtMkVp6nQGJJ3TETVKF5HCd4yymzJP</span>
        </div>
        <div className="text-sm truncate mt-2">
          <div>Operation</div>
          <span className="font-bold size-20" onClick={() => copyToClipboard("O1U8c2YQ8Fm4MWyCvtThZmFkCxgLUmChH9jhLQjaYkPeFY4NBbc")}>O1U8c2YQ8Fm4MWyCvtThZmFkCxgLUmChH9jhLQjaYkPeFY4NBbc</span>
        </div>
        <div className="text-sm truncate mt-2">
          <div>Wallet address</div>
          <span className="font-bold size-20" onClick={() => copyToClipboard("AU1L3YbT7SBwxdVwzacoonEgou5oXi5mNfXMaXqYhoL69GVrDUrE")}>AU1L3YbT7SBwxdVwzacoonEgou5oXi5mNfXMaXqYhoL69GVrDUrE</span>
        </div>
      </div>
    }
  </div>
  )
};

export default EventsViewer;
