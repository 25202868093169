import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SCReaderForm from "../modules/SCReaderForm";

const SCReader: React.FC = () => {
  const { network } = useParams();
  const [mainnet, setMainnet] = useState<boolean>();

  const changeNetwork = (netw:boolean) => {
    if(mainnet !== undefined && mainnet !== netw)
    {
      const currentUrl = window.location.href.split("/");
      let newUrl;
      newUrl = currentUrl.slice(0, 4).join("/");
      newUrl = `${newUrl}/${netw?"mainnet":"buildnet"}`;
      window.history.pushState({}, '', newUrl);
    }
    setMainnet(netw);
  }

  useEffect(() => {
    if(!network || network === "mainnet")
      changeNetwork(true);
    else
      changeNetwork(false);
  }, []);

  return (
    <div className="mt-3 mb-5">
      <div className="text-2xl truncate">SC Reader <span className="text-sm"><button className={mainnet || mainnet === undefined?"font-bold underline text-blue-500":" text-blue-500 hover:underline"} onClick={() => changeNetwork(true)}>mainnet</button> | <button className={!mainnet && mainnet !== undefined?"font-bold underline text-blue-500":" text-blue-500 hover:underline"} onClick={() => changeNetwork(false)}>buildnet</button></span></div>
      <SCReaderForm  mainnet={mainnet !== undefined ? mainnet : (!network || network === "mainnet"?true:false)} />
    </div>
  );
};

export default SCReader;
