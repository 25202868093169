import { useParams } from 'react-router-dom';
import { useNavigation } from '../hooks/useNavigation';
import { OperationData } from '../interfaces/Interfaces'
import { formatTimeAgo } from '../functions/Functions'
import { useMyContext } from "../context/GlobalContext"
import Pagination from './Pagination';

interface ListTransfersProps {
    transferts: OperationData[];
    nbPages : number;
    error: string | null;
}

function OperationList({ transferts, nbPages, error }: ListTransfersProps) { 
    const { goTo } = useNavigation();
    const { explorer} = useMyContext();
    const { address, token, block, page } = useParams();
    const parsedPage = page ? parseInt(page) : 1;
    const currentPage = Number.isNaN(parsedPage) ? 1 : parsedPage;

    return (
        <div>
            <div className="hidden lg:grid grid-cols-12 gap-4 rounded-md text-sm text-blue-950 bg-blue-100 text-center font-bold py-5 uppercase">
                <div className="">Status</div>
                <div className="">Age</div>
                <div className="col-span-2">Operation Id</div>
                <div className="col-span-3">From</div>
                <div className="col-span-3">To</div>
                <div className="">Amount</div>
                <div className="">Token</div>
            </div>
            <div className="hidden lg:block text-center border border-blue-100 rounded-md mt-1">
                {error &&
                    <div className="text-left text-red-500 p-2">{error}</div>
                }
                {transferts.map((transfert) => (
                <div key={transfert.ID_OP} className="grid grid-cols-12 gap-4 py-2 rounded-md hover:bg-blue-50">
                    <div className="">
                        {transfert.STATUS === 1 ?
                            <span className="rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-500">Success</span>
                        : (transfert.STATUS === 2 ?
                            <span className="rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-600">Pending</span>
                        : 
                            <span className="rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-600">Failed</span>
                        )}
                    </div>
                    <div className="truncate">{formatTimeAgo(transfert.TIMESTAMP)}</div>
                        <div className="col-span-2 truncate"><span role="button" className="text-blue-500 hover:underline" onClick={() => goTo(explorer["operation"] + transfert.ID_OP)}>{transfert.ID_OP}</span></div>
                        <div className="col-span-3 truncate">
                            {address !== transfert.FROM_ADDR?
                                <span role="button" className="text-blue-500 hover:underline" onClick={() => goTo(explorer["address"] + transfert.FROM_ADDR)}>{transfert.FROM_ADDR}</span>
                            :
                                <span className="italic">{transfert.FROM_ADDR}</span>
                            }
                        </div>
                        <div className="col-span-3 truncate">
                            {address !==  transfert.TO_ADDR?
                                <span role="button" className="text-blue-500 hover:underline" onClick={() => goTo(explorer["address"] + transfert.TO_ADDR)}>{transfert.TO_ADDR}</span>
                            :
                                <span className="italic">{transfert.TO_ADDR}</span>
                            }
                        </div>
                        {!transfert.SYMBOL ?
                            <div className="truncate col-span-2">NFT #{transfert.AMOUNT}</div>
                        :
                            <div className="col-span-2 grid grid-cols-2">
                                <div className="truncate">
                                    {parseInt(transfert.AMOUNT)/Math.pow(10, transfert.DECIMALS) >= 0.01 ? 
                                        (parseInt(transfert.AMOUNT)/Math.pow(10, transfert.DECIMALS)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
                                    :
                                        <span className="text-sm">{"<"} 0.01</span>
                                    }
                                </div>
                                <div className="truncate"><span role="button" className="text-blue-500 hover:underline" onClick={() => goTo(explorer["token"] + transfert.CONTRACT)}>{transfert.SYMBOL}</span></div>
                            </div>
                        }
                    </div>
                ))}
            </div>
            <div className="lg:hidden">
                {error ?
                    <div className="text-left text-red-500 p-2">{error}</div>
                : null }
                {transferts.map((transfert) => (
                    <div key={transfert.ID_OP} className="text-center border border-blue-100 rounded-md mt-1 hover:bg-blue-50">
                        <div className="truncate bg-blue-100 rounded-md p-1"><span role="button" className="text-blue-500 font-bold hover:underline" onClick={() => goTo(explorer["operation"] + transfert.ID_OP)}>{transfert.ID_OP}</span></div>
                        <div className="grid grid-cols-5 px-1">
                            <div className="truncate col-span-2">
                                {address !== transfert.FROM_ADDR?
                                    <span role="button" className="text-blue-500 hover:underline" onClick={() => goTo(explorer["address"] + transfert.FROM_ADDR)}>{transfert.FROM_ADDR}</span>
                                :
                                    <span className="italic">{transfert.FROM_ADDR}</span>
                                }
                            </div>
                            {transfert.STATUS === 1 ?
                                <div className="animate-pulse text-blue-500">
                                    <svg className="w-3 h-3 mx-auto mt-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                    </svg>
                                </div>
                            : (transfert.STATUS === 2 ?
                                <div className="animate-pulse text-yellow-500">
                                    <svg className="w-3 h-3 mx-auto mt-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                    </svg>
                                </div>
                            : <div className="animate-pulse text-red-500">
                                <svg className="w-3 h-3 mx-auto mt-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                                </svg>
                            </div>
                            )}
                            <div className="truncate col-span-2">
                                {address !== transfert.TO_ADDR?
                                    <span role="button" className="text-blue-500 hover:underline" onClick={() => goTo(explorer["address"] + transfert.TO_ADDR)}>{transfert.TO_ADDR}</span>
                                :
                                    <span className="italic">{transfert.TO_ADDR}</span>
                                }
                            </div>
                        </div>
                        <div className="grid grid-cols-2 px-1">
                            <div className="text-start">
                                {parseInt(transfert.AMOUNT)/Math.pow(10, transfert.DECIMALS) >= 0.01 ? 
                                    (parseInt(transfert.AMOUNT)/Math.pow(10, transfert.DECIMALS)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
                                :
                                    <span className="text-sm">{"<"} 0.01</span>
                                } {transfert.SYMBOL}
                            </div>
                            <div className="text-end">{formatTimeAgo(transfert.TIMESTAMP)} ago</div>
                        </div>
                    </div>
                ))}
            </div>
            {nbPages?
                <div className="text-center mt-2">
                    <Pagination nbPages={nbPages} page={currentPage} path={(block ? explorer["block"] + block : ((token ? explorer["token"] + token : explorer["address"] + address)))} />
                </div>
            : null }
        </div>
    );
}
        
export default OperationList;