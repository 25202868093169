import React, { useState, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useNavigation } from '../hooks/useNavigation';

interface Props {
    mainnet: boolean;
}

function AnalyzerBar(props: Props) {
    const { mainnet } = props;
    const { scaddress } = useParams();
    const { goTo } = useNavigation();
    const [searchInput, setSearchInput] = useState<string>('');

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        setSearchInput(value);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    function search() {
      if(searchInput.length > 3 && searchInput[0] === "A" && searchInput[1] === "S")
      {
        goTo("/sc-analyzer/" + searchInput + "/" + (mainnet ? "mainnet" : "buildnet"));
      }
    }

    useEffect(() => {
        if(scaddress && scaddress.length > 5) setSearchInput(scaddress);
    }, [scaddress]);

    return (
    <div className="mb-10">
        <div className="">
            <label htmlFor="table-search" className="sr-only">Search</label>
            <div className="flex py-1">
                <input onChange={handleInputChange} onKeyDown={handleKeyDown} onFocus={(e) => e.target.select()} type="text" id="search" className="p-2 text-sm text-gray-900 border border-blue-300 rounded-lg w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" placeholder="Smart contract address" value={searchInput}/>
                <button onClick={search} type="button" className="text-blue-50 bg-blue-400 hover:bg-blue-500 focus:ring-2 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-sm p-2 text-center ms-2 w-1/6">
                    <svg className="w-4 h-4 text-blue-50 mx-auto md:hidden" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                    <span className="hidden md:block">Analyze</span>
                </button>
            </div>
        </div>
    </div>
    );
}
        
export default AnalyzerBar;