import { useEffect, useState } from "react";
import { Args, CHAIN_ID, ClientFactory, DefaultProviderUrls, bytesToStr, fromMAS } from "@massalabs/massa-web3";

interface Props {
  mainnet: boolean;
}

function SCReaderForm(props: Props) {
  const { mainnet } = props;
  const [error, setError] = useState<string | null>(null);
  const [ scAddress, setScAddress] = useState<string>("");
  const [ tgFunction, setTgFunction] = useState<string>("");
  const [ callerAddress, setCallerAddress] = useState<string>("");
  const [ placeholder, setPlaceholder ] = useState("boolean");
  const [ input, setInput ] = useState("");
  const [ coins, setCoins ] = useState<number>(0);
  const [ paramsStored, setParamsStored ] = useState<any[]>([]);
  const [ params, setParams ] = useState<Args>(new Args());
  const [ scReaded, setScReaded] = useState<any>();

  function serializeParams() {
    const paramsSerialized = new Args();
  
    paramsStored.forEach(element => {
      const [key, value] = Object.entries(element)[0];

      const handlers:{ [key: string]: (value: any) => void } = {
        boolean: () => paramsSerialized.addBool(value as boolean),
        string: () => paramsSerialized.addString(value as string),
        u8: () => paramsSerialized.addU8(parseInt(value as string)),
        u32: () => paramsSerialized.addU32(parseInt(value as string)),
        u64: () => paramsSerialized.addU64(BigInt(value as string)),
        u128: () => paramsSerialized.addU128(BigInt(value as string)),
        u256: () => paramsSerialized.addU256(BigInt(value as string)),
        i32: () => paramsSerialized.addI32(parseInt(value as string)),
        i64: () => paramsSerialized.addI64(BigInt(value as string)),
        i128: () => paramsSerialized.addI128(BigInt(value as string)),
        f32: () => paramsSerialized.addF32(parseInt(value as string)),
        f64: () => paramsSerialized.addF64(parseInt(value as string))
      };
      
      if (handlers[key]) {
        handlers[key](value);
      } else {
        setError(`Type de paramètre non pris en charge : ${key}`);
      }
    });

    setParams(paramsSerialized);
  }

  function addToParams() {
    if(input.length < 1) return;
    setParamsStored(prevParams => [...prevParams, { [placeholder]: placeholder === "boolean" ? (input.toUpperCase() === "TRUE" || input === "1" ? true : false) : input }]);
    setInput("");
  }

  function removeParams(index: number) {
    setParamsStored(prevParams => prevParams.filter((_, i) => i !== index));
  };

  async function readSC() {
    if(scAddress.length < 5 || tgFunction.length < 1) return;
    try {
      setScReaded(undefined);
      setError(null);
      const providerUrl = mainnet ? DefaultProviderUrls.MAINNET : DefaultProviderUrls.BUILDNET;
      const chainId = mainnet ? CHAIN_ID.MainNet : CHAIN_ID.BuildNet;
      const web3Client = await ClientFactory.createDefaultClient(providerUrl, chainId, true);

      const read = await web3Client.smartContracts().readSmartContract({
        callerAddress: callerAddress,
        targetAddress: scAddress,
        targetFunction:tgFunction,
        parameter: params.serialize(),
        coins: coins ? fromMAS(coins) : fromMAS(0)
      });

      if (read.returnValue.length === 0) {
        setError(read.returnValue.toString());
      }

      setScReaded(read);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      setError(errorMessage);
    }
  }

  useEffect(() => {
    serializeParams();
  }, [paramsStored])

  return (
    <div className="mt-3 mb-10">
      <div className="my-3">
        <div className="text-sm">Smart contract address</div>
        <input type="text" onChange={(e) => setScAddress(e.target.value.trim())} placeholder="Smart contract address" className="p-2 text-sm text-gray-900 border border-blue-300 rounded-lg w-full bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" />
      </div>
      <div className="my-3">
        <div className="text-sm">Target function</div>
        <input type="text" onChange={(e) => setTgFunction(e.target.value.trim())} placeholder="Target function" className="p-2 text-sm text-gray-900 border border-blue-300 rounded-lg w-full bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" />
      </div>
      <div className="my-3">
        <div className="text-sm">Caller address</div>
        <input type="text" onChange={(e) => setCallerAddress(e.target.value.trim())} placeholder="Caller address" className="p-2 text-sm text-gray-900 border border-blue-300 rounded-lg w-full bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" />
      </div>
      <div className="my-3">
        <div className="text-sm">Transfered coins (MAS)</div>
        <input type="number" onChange={(e) => setCoins(parseInt(e.target.value))} placeholder="0" pattern="[0-9]" min={0} value={coins} step={0} className="p-2 text-sm text-gray-900 border border-blue-300 rounded-lg w-full bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" />
      </div>
      <div className="my-3">
        <div className="text-sm">Parameters</div>
        <select onChange={(e) => setPlaceholder(e.target.value)} defaultValue={"boolean"} className="p-2 text-sm text-gray-900 border border-blue-300 rounded-lg w-full bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200">
          <option value="boolean">Boolean</option>
          <option value="string">String</option>
          <option value="u8">U8</option>
          <option value="u32">U32</option>
          <option value="u64">U64</option>
          <option value="u128">U128</option>
          <option value="u256">U256</option>
          <option value="i32">I32</option>
          <option value="i64">I64</option>
          <option value="i128">I128</option>
          <option value="f32">F32</option>
          <option value="f64">F64</option>
        </select>
        <div className="relative w-full">
          <input onChange={(e) => setInput(e.target.value)} value={input} type="text" placeholder={placeholder} className="mt-1 p-2 text-sm text-gray-900 border border-blue-300 rounded-lg w-full bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" />
          <button onClick={addToParams} className="absolute absolute end-1.5 bottom-1 text-blue-50 bg-blue-400 hover:bg-blue-500 focus:ring-2 focus:outline-none focus:ring-blue-200 font-medium text-sm text-center rounded-lg py-1 px-4">+</button>
        </div>
        <div className="text-sm truncate my-1">Serialized parameters: [{params.serialize().toString()}]</div>
        <div>{paramsStored.map((param, index) => {
                const key = Object.keys(param)[0];
                const value = param[key];
                return (
                    <div className="truncate" key={index}>
                        <button className="text-red-500 hover:text-red-600 hover:underline font-bold me-2" onClick={() => removeParams(index)}>x</button>
                        <strong>[{index}] {key}:</strong> {value.toString()}
                    </div>
                );
            })}
        </div>
        <div className="my-2"><button onClick={readSC} className="p-3 bg-blue-400 hover:bg-blue-500 rounded text-blue-50 mt-5 focus:ring-2 focus:outline-none focus:ring-blue-200">Read Smart Contract</button></div>
        {error && <div className="my-2 bg-red-50 rounded p-2 text-red-600 font-bold my-3">{error}</div>}
        {scReaded &&
          <div className="mt-10">
            <div className="bg-blue-50 rounded"><span className="m-2">Returned value converted to string</span>
              <textarea readOnly cols={30} rows={3} value={bytesToStr(scReaded.returnValue)} className="mb-3 block p-2.5 w-full text-sm rounded-lg text-gray-900 border border-blue-300 w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" />
            </div>
            <div className="bg-blue-50 rounded"><span className="m-2">Response</span>
              <textarea readOnly cols={30} rows={15} value={JSON.stringify(scReaded)} className="mb-3 block p-2.5 w-full text-sm rounded-lg text-gray-900 border border-blue-300 w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" />
            </div>
          </div>
        }
      </div>
    </div>
    );
}
        
export default SCReaderForm;