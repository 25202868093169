import { createContext, useContext, useState, ReactNode } from 'react';

interface MyContextType {
  currentPage: string;
  setCurrentPage: (value: string) => void;
  explorer: any;
  massaPrice: number;
  setMassaPrice: (value: number) => void;
}

const MyContext = createContext<MyContextType | undefined>(undefined);

export const MyContextProvider = ({ children }: { children: ReactNode }) => {
  const [currentPage, setCurrentPage] = useState<string>("/");
  const explorer = {
    "operation": "/token-explorer/operation/",
    "block": "/token-explorer/block/",
    "address": "/token-explorer/address/",
    "token": "/token-explorer/token/"
  };
  const [massaPrice, setMassaPrice] = useState<number>(0);

  return (
    <MyContext.Provider value={
        {   currentPage, setCurrentPage, explorer, massaPrice, setMassaPrice
        }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error('useMyContext need to used inside MyContextProvider');
  }
  return context;
};