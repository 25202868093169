import React, { useEffect, useState, ChangeEvent, KeyboardEvent } from 'react';
import { useMyContext } from "../context/GlobalContext"
import { useNavigation } from '../hooks/useNavigation';
import { useParams } from 'react-router-dom';

function SearchBar() {
    const { operation, block, address, token } = useParams();
    const { explorer} = useMyContext();
    const { goTo } = useNavigation();
    const [searchInput, setSearchInput] = useState<string>('');

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        setSearchInput(value);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            search();
        }
    };

    function search() {
      if(searchInput.length > 3 || searchInput === "0")
      {
          switch (searchInput[0]) {
              case "B":
                  console.log("BLOCK");
                  goTo(explorer["block"] + searchInput);
                  break;
          
              case "O":
                  console.log("OPERATION");
                  goTo(explorer["operation"] + searchInput);
                  break;
                  
              case "A":
                  if(searchInput[1] === "S") {
                      console.log("TOKEN");
                      goTo(explorer["token"] + searchInput);
                  }
                  else {
                      console.log("ADDRESS");
                      goTo(explorer["address"] + searchInput);
                  }
                  break;
                
              case "0":
                    console.log("NATIVE TOKEN");
                    goTo(explorer["token"] + searchInput);
          }
      }
  }

    useEffect(() => {
        if(operation) setSearchInput(operation);
        if(block) setSearchInput(block);
        if(address) setSearchInput(address);
        if(token) setSearchInput(token);
    }, [operation, block, address, token]);

    return (
    <div className="mt-3 mb-10">
        <div className="text-2xl truncate">Token Explorer <span className="text-sm">mainnet</span></div>
        <div className="">
            <label htmlFor="table-search" className="sr-only">Search</label>
            <div className="flex py-1">
                <input onChange={handleInputChange} onKeyDown={handleKeyDown} onFocus={(e) => e.target.select()} type="text" id="search" className="p-2 text-sm text-gray-900 border border-blue-300 rounded-lg w-5/6 bg-gray-50 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-blue-200" placeholder="Block / Operation / Wallet address" value={searchInput} />
                <button onClick={search} type="button" className="text-blue-50 bg-blue-400 hover:bg-blue-500 focus:ring-2 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-sm p-2 text-center ms-2 w-1/6">
                    <svg className="w-4 h-4 text-blue-50 mx-auto md:hidden" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                    <span className="hidden md:block">Search</span>
                </button>
            </div>
        </div>
    </div>
    );
}
        
export default SearchBar;