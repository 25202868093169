import React, { useEffect } from "react";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useLocation } from "react-router-dom";
import { useNavigation } from '../hooks/useNavigation';
import { useMyContext } from '../context/GlobalContext';
import { setCookie, getCookie } from '../functions/Functions';

const navigation = [
  { name: 'Token Explorer', href: '/token-explorer', inside: [] },
  { name: 'Portfolio', href: '/portfolio', inside: [] },
  { name: 'Tools', href: '#', inside: [{ name: 'Events Viewer', href: '/events-viewer' }, { name: 'SC Analyzer', href: '/sc-analyzer' }, { name: 'SC Reader', href: '/sc-reader' }, { name: 'Data Stored Reader', href: '/data-stored-reader' }] },
  { name: 'Contact', href: '#', inside: [{ name: 'Telegram', href: 'https://t.me/xLaPerle' }, { name: 'E-Mail', href: 'mailto:contact@massa.ga' }] },
  { name: 'Donate', href: '/donate', inside: [] },
]

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

const Header: React.FC = () => {
  const location = useLocation();
  const { goTo } = useNavigation();
  const { currentPage, setCurrentPage, setMassaPrice } = useMyContext();
  const cookieMassaPriceName = 'coingecko';

  const getMassaPrice = async () => {
    console.log("Getting Massa price..");
    try {
      const coingecko = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=massa&vs_currencies=usd");
      if (!coingecko.ok) {
        throw new Error('Error Coingecko');
      }
      const json = await coingecko.json();
      setMassaPrice(json.massa.usd);
      setCookie(cookieMassaPriceName, json.massa.usd);
    } catch {
      setMassaPrice(0);
    }
}

  useEffect(() => {
    if(getCookie(cookieMassaPriceName) === 0) {
      getMassaPrice();
    }
    else {
      setMassaPrice(parseFloat(getCookie(cookieMassaPriceName)));
    };

    setCurrentPage(`/${location.pathname.split('/')[1]}`);
  });

  return (
    <Disclosure as="nav" className="bg-blue-100 fixed z-10 w-screen left-0 top-0 opacity-95">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
            {/* Mobile menu button*/}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-blue-500 hover:bg-blue-400 hover:text-blue-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-50">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Main menu</span>
              <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
              <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
            </DisclosureButton>
          </div>
          <div className="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
            <div className="flex flex-shrink-0 items-center text-sm font-bold text-blue-500">
              <button onClick={() => goTo("/")}>MASSA.GA</button>
            </div>
            <div className="hidden sm:ml-6 md:block">
              <div className="flex space-x-4">
                {navigation.map((item) => (
                  (item.inside.length > 0 ?
                    <Menu
                      key={item.name}
                      as="div"
                      className="relative inline-block text-left"
                    >
                      <div>
                        <MenuButton
                          key={item.name}
                          aria-current={item.href === currentPage ? 'page' : undefined}
                          className={classNames(
                            item.href === currentPage || item.inside.some(subItem => subItem.href === currentPage) ? 'inline-flex bg-blue-600 text-blue-50' : 'inline-flex text-blue-500 hover:bg-blue-400 hover:text-blue-50',
                            'rounded-md px-3 py-2 text-sm font-medium',
                          )}
                        >
                          {item.name}
                          <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-blue-500" />
                        </MenuButton>
                      </div>
                      <MenuItems
                        transition
                        className="absolute left-0 z-10 mt-4 origin-top-right rounded-md bg-blue-100 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <div className="py-1 mx-1">
                         {item.inside.map((itemInside) => (
                          <MenuItem key={itemInside.name}>
                            <button
                              onClick={() => goTo(itemInside.href)}
                              className={classNames(
                                itemInside.href === currentPage ? 'bg-blue-600 text-blue-50' : 'text-blue-500 hover:bg-blue-400 hover:text-blue-50',
                                'block w-full text-left rounded-md px-3 py-2 text-sm font-medium text-nowrap mb-0.5',
                              )}
                            >
                              {itemInside.name}
                            </button>
                          </MenuItem>
                          ))}
                        </div>
                      </MenuItems>
                    </Menu>
                  :
                    <button
                      key={item.name}
                      onClick={() => goTo(item.href)}
                      aria-current={item.href === currentPage ? 'page' : undefined}
                      className={classNames(
                        item.href === currentPage ? 'bg-blue-600 text-blue-50' : 'text-blue-500 hover:bg-blue-400 hover:text-blue-50',
                        'rounded-md px-3 py-2 text-sm font-medium',
                      )}
                    >
                      {item.name}
                    </button>
                  )
                ))}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* For futur */}
          </div>
        </div>
      </div>

      <DisclosurePanel className="md:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {navigation.map((item) => (
            (item.inside.length > 0 ?
              <Menu
                key={item.name}
                as="div"
                className={classNames(
                  item.href === currentPage ? 'bg-blue-600 text-blue-50' : 'text-blue-500',
                  'block w-full rounded-md text-base font-medium',
                )}
              >
                <div>
                  <MenuButton
                    key={item.name}
                    aria-current={item.href === currentPage ? 'page' : undefined}
                    className={classNames(
                      item.href === currentPage || item.inside.some(subItem => subItem.href === currentPage) ? 'bg-blue-600 text-blue-50' : 'text-blue-500 hover:bg-blue-400 hover:text-blue-50',
                      'inline-flex w-full rounded-md px-3 py-2 text-base font-medium',
                    )}
                  >
                    {item.name}
                    <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-blue-500" />
                  </MenuButton>
                </div>
                <MenuItems
                  transition
                >
                  <div className="py-1 mx-1">
                   {item.inside.map((itemInside) => (
                    <DisclosureButton
                        key={itemInside.name}
                        as="button"
                        onClick={() => goTo(itemInside.href)}
                        className={classNames(
                          itemInside.href === currentPage ? 'bg-blue-500 text-blue-50' : 'text-blue-500 hover:bg-blue-300 hover:text-blue-50',
                          'block w-full text-left rounded-md px-3 py-2 text-sm font-medium text-nowrap mb-0.5',
                        )}
                      >
                        {itemInside.name}
                    </DisclosureButton>
                    ))}
                  </div>
                </MenuItems>
              </Menu>
            :
              <DisclosureButton
                key={item.name}
                as="button"
                onClick={() => goTo(item.href)}
                aria-current={item.href === currentPage ? 'page' : undefined}
                className={classNames(
                  item.href === currentPage ? 'bg-blue-600 text-blue-50' : 'text-blue-500 hover:bg-blue-400 hover:text-blue-50',
                  'block w-full text-left rounded-md px-3 py-2 text-base font-medium',
                )}
              >
                {item.name}
              </DisclosureButton>
            )
          ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
};

export default Header;
