import { useNavigate } from 'react-router-dom';

export const useNavigation = () => {
  const navigate = useNavigate();

  const goTo = (path: string, params?: Record<string, any>) => {
    if (path.startsWith('/')) {
      navigate(path, { state: params });
    } else {
      window.open(path, '_blank');
    }
  };

  return {
    goTo
  };
};