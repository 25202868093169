import React, { useEffect, useState } from "react";
import { OperationData } from "../interfaces/Interfaces"
import OperationList from "../modules/OperationList";
import SearchBar from "../modules/SearchBar";

const TokenExplorer: React.FC = () => {
  const [last_transferts, setLastTransferts] = useState<OperationData[]>([]);
  const [error, setError] = useState<string | null>(null);
  
  const fetchLastTransferts = async () => {
    try {
      const responseTransferts = await fetch('https://api.massa.ga/last_transferts?n=15');
      if (!responseTransferts.ok) {
        throw new Error('Error retrieving data..');
      }
      const resultTransferts: OperationData[] = await responseTransferts.json();
      setLastTransferts(resultTransferts);
      setError(null);
    } catch (error) {
      setError('Error retrieving data..');
      setLastTransferts([]);
    }
  };

  useEffect(() => {
    fetchLastTransferts();

    const interval = setInterval(() => {
      fetchLastTransferts();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
  <div>
    <SearchBar />
    <div className="mt-3 mb-5">
      <div className="text-2xl truncate">Latest transactions</div>
      <OperationList transferts={last_transferts} nbPages={0} error={error}/>
    </div>
  </div>
  )
};

export default TokenExplorer;
