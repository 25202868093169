import { Client, toMAS, Args, bytesToU256, CHAIN_ID } from "@massalabs/massa-web3";
import React, { useEffect, useState } from "react";
import { useMyContext } from "../context/GlobalContext";
import { cuteNumber } from "../functions/Functions";
import { LB_QUOTER_ADDRESS, IQuoter, Token, RouteV2, PairV2, WMAS as _WMAS, WETH as _WETH, USDC as _USDC, DAI as _DAI, USDT as _USDT, TradeV2, TokenAmount, parseUnits } from "@dusalabs/sdk";

interface PortfolioInterface {
  address: string;
  web3Client: Client;
}

interface TokenList {
  ID: number;
  CONTRACT: string;
  NAME: string;
  SYMBOL: string;
  DECIMALS: number;
}

interface BalanceList {
  [scAddress: string]: number;
}

const PortfolioList: React.FC<PortfolioInterface> = ({ address, web3Client }) => {
  const { massaPrice } = useMyContext();
  const [totalUSD, setTotalUSD] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [tokenList, setTokenList] = useState<TokenList[]>([]);
  const [balanceList, setBalanceList] = useState<BalanceList>({});
  const [priceList, setPriceList] = useState<BalanceList>({});
  const [error, setError] = useState<string | null>(null);

  const getMasBalance = async () => {
    try {
      const balanceMAS = await web3Client.wallet().getAccountBalance(address);
      if (balanceMAS) {
        setBalance(parseFloat(toMAS(balanceMAS.final).toString()));
        setTotalUSD(parseFloat(toMAS(balanceMAS.final).toString())*massaPrice);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  };

  const getTokenList = async () => {
    try {
      const response = await fetch('https://api.massa.ga/tokens');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      setTokenList(jsonData);
    } catch (error) {
      setError((error as Error).message);
    }
  };

  const getTokenBalance = async (token: TokenList) => {
    try {
      setBalanceList({});
      const tBalance = await web3Client.smartContracts().readSmartContract({
        targetAddress: token.CONTRACT,
        targetFunction: 'balanceOf',
        parameter: new Args().addString(address),
      });
      const balanceValue = parseInt(bytesToU256(tBalance.returnValue).toString())/Math.pow(10, token.DECIMALS);
      if(balanceValue > 0)
      {
        setBalanceList(prevTableau => ({
          ...prevTableau,
          [token.CONTRACT]: balanceValue
        }));
        getPriceToken(token);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  };

  const getPriceToken = async (token: TokenList) => {
    const chainId = 1;

    if(_WMAS[chainId].address === token.CONTRACT)
    {
      setPriceList(prevTableau => ({
        ...prevTableau,
        [token.CONTRACT]: massaPrice
      }));
      return;
    }

    const inputToken = new Token(chainId, token.CONTRACT, token.DECIMALS, token.SYMBOL, token.NAME);
    const outputToken = _WMAS[chainId];

    const allTokenPairs = PairV2.createAllTokenPairs(
      inputToken,
      outputToken,
      [ inputToken, _WMAS[chainId], _WETH[chainId], _USDC[chainId], _DAI[chainId], _USDT[chainId] ],
    );
    
    const allPairs = PairV2.initPairs(allTokenPairs);

    const allRoutes = RouteV2.createAllRoutes(
      allPairs,
      inputToken,
      outputToken
    );

    const trades = await TradeV2.getTradesExactIn(
      allRoutes,
      new TokenAmount(inputToken, parseUnits('1', inputToken.decimals)),
      outputToken,
      false,
      false,
      web3Client,
      chainId,
    );

    let bestTrade;

    try {
      bestTrade = TradeV2.chooseBestTrade(trades, true);
    } catch (error) {
      console.log("No route for: " + token.SYMBOL);
      return;
    }

    try {
      const quoter = new IQuoter(LB_QUOTER_ADDRESS[chainId], web3Client);

      const prices = await quoter.findBestPathFromAmountIn(
        bestTrade.route.pathToStrArr(),
        parseUnits('1', inputToken.decimals).toString(),
      );

      const unitPrice = prices.virtualAmountsWithoutSlippage[ prices.virtualAmountsWithoutSlippage.length - 1 ];
      const usdValue = parseInt(unitPrice.toString())/Math.pow(10, outputToken.decimals)*massaPrice;
      setPriceList(prevTableau => ({
        ...prevTableau,
        [token.CONTRACT]: usdValue
      }));
    } catch (error) {
      setPriceList(prevTableau => ({
        ...prevTableau,
        [token.CONTRACT]: 0
      }));
      console.log("Error for: " + token.SYMBOL);
      return;
    }
  }

  useEffect(() => {
    getMasBalance();
    getTokenList();
  }, [address]);

  useEffect(() => {
    const fetchTokenBalances = async () => {
      try {
        await Promise.all(tokenList.map(token => {
          if (token.CONTRACT !== "0") {
            return getTokenBalance(token);
          }
          return Promise.resolve();
        }));
      } catch (error) {
        setError((error as Error).message);
      }
    };

    fetchTokenBalances();
  }, [tokenList]);

  useEffect(() => {
    setTotalUSD(0);
    let total = 0;
    for (const sc in balanceList) {
      if (balanceList.hasOwnProperty(sc)) {
        if(priceList[sc])
          total = total + balanceList[sc]*priceList[sc];
      }
    }
    setTotalUSD(total + (balance*massaPrice));
  }, [priceList]);

  return (
    <div>
      {error ? <div className="error">{error}</div> : <div className="text-xl">Total <span className="font-bold">${cuteNumber(totalUSD, 2)}</span></div> }
      <div>
        <div className="grid grid-cols-8 lg:grid-cols-12 gap-4 rounded-md text-sm text-blue-950 bg-blue-100 text-center font-bold py-5 uppercase">
          <div className="hidden lg:block col-span-2">Asset</div>
          <div className="col-span-2 lg:col-span-1">Symbol</div>
          <div className="hidden lg:block col-span-3">Contract Address</div>
          <div className="col-span-2">Quantity</div>
          <div className="col-span-2">Price</div>
          <div className="col-span-2">Value</div>
        </div>
        <div className="block text-center border border-blue-100 rounded-md mt-1">
          <div className="grid grid-cols-8 lg:grid-cols-12 gap-4 py-2 rounded-md hover:bg-blue-50">
            <div className="hidden lg:block col-span-2">Native Massa</div>
            <div className="col-span-2 lg:col-span-1">MAS</div>
            <div className="hidden lg:block col-span-3">-</div>
            <div className="col-span-2">{balance ? cuteNumber(balance, 8) : '-'}</div>
            <div className="col-span-2">{massaPrice ? `$${cuteNumber(massaPrice, 3)}` : '-'}</div>
            <div className="col-span-2">{balance && massaPrice ? `$${cuteNumber(balance * massaPrice, 3)}` : '-'}</div>
          </div>
          {tokenList.map((token) => (
            token.CONTRACT !== "0" && balanceList[token.CONTRACT] !== undefined &&
            <div key={token.ID} className="grid grid-cols-8 lg:grid-cols-12 gap-4 py-2 rounded-md hover:bg-blue-50">
              <div className="hidden lg:block col-span-2">{token.NAME}</div>
              <div className="col-span-2 lg:col-span-1">{token.SYMBOL}</div>
              <div className="hidden lg:block col-span-3 truncate">{token.CONTRACT}</div>
              <div className="col-span-2">
                {cuteNumber(balanceList[token.CONTRACT], 8).replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '')}
              </div>
              <div className="col-span-2">{priceList[token.CONTRACT] ? (priceList[token.CONTRACT] < 0.001 ? "< $0.001" : "$"+cuteNumber(priceList[token.CONTRACT], 3)) : "-"}</div>
              <div className="col-span-2">{priceList[token.CONTRACT] && priceList[token.CONTRACT]*balanceList[token.CONTRACT] > 0 ? (priceList[token.CONTRACT]*balanceList[token.CONTRACT] < 0.001 ? "< $0.001" : "$"+cuteNumber(priceList[token.CONTRACT]*balanceList[token.CONTRACT], 3)) : "$0"}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioList;
